/* You can add global styles to this file, and also import other style files */
@import 'theme';

@tailwind base;
@tailwind components;
@tailwind utilities;

// angular update and tailwind integration start //
$lab-blue: #0c83ff;

.lab-bg-blue {
  background-color: $lab-blue;
}

.lab-text-blue {
  color: $lab-blue;
}

.lab-border-blue {
  border-color: $lab-blue;
}

.mat-mdc-tab-header {
  background-color: white;
  border-bottom: 1px solid #c7c7c7;
}

.mat-mdc-dialog-content {
  max-height: unset;
}

.mdc-dialog__actions {
  padding: 20px !important;
}

.mdc-tab {
  letter-spacing: unset;
  text-transform: uppercase;
  color: rgba(0, 0, 0, .87);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: black;
}

// angular update and tailwind integration end //


html {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

body {
  width: 100vw;
  height: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

html,
body {
  margin: 0px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch !important;
}

.main-wrapper {
  // width: 100vw;
  // height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

input,
select,
textarea {
  font-size: 16px !important;
}

.primary {
  color: #0c83ff;
}

.rounded-button {
  border-radius: 22px !important;
  width: 80px;
  float: right;
  top: 10px;
}

.center {
  margin: 0px;
  text-align: center;
}

.right {
  margin: 0px;
  text-align: right;
}

.basic-container {
  padding: 0px;
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.right-align {
  text-align: right;
}

.button-large-fill {
  width: 100%;
  min-height: 75px;
  font-size: 20px;
  border-radius: 0px !important;
  position: fixed !important;
  bottom: 0;
  right: 0;
  transform: translateZ(0) !important;
  -webkit-transform: translateZ(0) !important;
}

.mat-toolbar {
  background-color: white;
  border-bottom: 1px solid #e1e1e1;
}

.row {
  margin: 15px 20px;
}

.row-input {
  padding: 0 20px;
}

.customTipRow {
  margin-top: 15px;
}

.row-wop {
  margin: 10px 0;
  max-width: 100%;
  overflow-x: hidden;
}

.fixed-height {
  height: 50%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mb-20 {
  margin-bottom: 20px;
}

.bottom-sheet-panel .mat-dialog-container {
  width: 350px;
}

.label {
  display: flex;
}

.label span {
  flex: 1;
}

.danger {
  color: red;
}

.mat-cell {
  font-size: 16px !important;
}

mat-toolbar {
  text-align: center;
}

.title-position {
  flex: 1 1 auto;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}

.arrow-position {
  position: absolute;
  bottom: 88%;
  right: 2%;
}

.arrow-position-left {
  position: absolute;
  bottom: 18%;
  right: 1%;
}

.iconButton {
  background: white;
  border: 0px;
  position: absolute;
  right: 10px;
}

.box {
  border: 1px solid white;
  width: 40px;
  height: 40px;
  margin: auto;
  line-height: 40px;
}

// .mat-bottom-sheet-container{
//   // min-width: 80vw !important;
//   overflow: unset;
//   border-top-left-radius: 24px !important;
//   border-top-right-radius: 24px !important;
// }

// .bottom-sheet-panel{
//   border-top-left-radius: 24px !important;
//   border-top-right-radius: 24px !important;
// }

.round-borders {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.mlr {
  margin: 0 20px 0px 20px;
  padding: 7px 10px 0 10px;
}

.calculatetipchange {
  padding: 16px 14px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-width: 1px;
}

.mat-chip.mat-standard-chip {
  background-color: white !important;
  color: rgba(18, 157, 238, 0.7) !important;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3 !important;
  color: white !important;
}

.calculatetipchange::after {
  background-color: #2196f3 !important;
  color: white !important;
}

.paymentchangebutton {
  color: rgba(18, 157, 238, 0.7);
  float: right;
  margin-top: 2px !important;
}

.orderInstruction {
  padding: 0 20px;
}

.paymentChangeLabel {
  margin-top: 10px;
  padding-right: 20px;
  margin-bottom: 10px;
}

i {
  vertical-align: middle !important;
}

.store-bottom-sheet-container {
  max-height: 50vh !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

.mat-bottom-sheet-container {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

.mat-bottom-sheet-container.mat-bottom-sheet-container.payment-bottom-sheet-container {
  padding: 0.6rem 0;

}

.payment-bottom-sheet-container {
  max-height: 80vh !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  -webkit-transform: translate3d(0, 0, 0);
}

.schedule-bottom-sheet-container {
  max-height: 80vh !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  // padding: 17px 9px 10px 9px !important;
  padding: 25px 0px 10px 0px !important;
}

.payment-option {
  margin: 10px 0;
}

.mat-button-toggle-button {
  height: 45px;
}

.mat-button-toggle-label-content {
  line-height: 46px !important;
}

.disclosure {
  font-size: 14px;
  color: grey;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

.mat-card-image {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .content {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.verified {
  font-size: 10px;
  color: grey;
  display: block;
}

.verified img {
  display: inline;
  height: 10px;
}

.mat-toolbar {
  font: 500 20px/20px Roboto, "Helvetica Neue", sans-serif;
}

.mt-8 {
  margin-top: 8px;
}

.main-menu .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0px !important;
}

.clear {
  clear: both;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.disabled {
  pointer-events: none;
}

.schedule-section-btn {
  .mat-button-toggle-label-content {
    line-height: 45px !important;
  }

  .mat-button-toggle-appearance-standard button {
    height: 45px !important;
  }
}

.main-menu {
  position: relative;
  display: contents;
}

app-order-summary-master.ng-star-inserted {
  position: relative;
}

.centre {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.mat-flat-button.mat-primary[disabled] {
  background-color: #e2e2e2;
  opacity: 1;
}

.phone-section {
  .mat-input-element:disabled {
    color: #636363;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: none;
    border-bottom: 1px solid #979797;
  }

  .mat-stroked-button {
    font-weight: bold;
  }

  .mat-flat-button {
    font-weight: bold;
  }
}

.promo-section {
  .mat-input-element:disabled {
    color: #636363;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: none;
    border-bottom: 1px solid #979797;
  }

  .mat-stroked-button {
    font-weight: bold;
  }

  .mat-flat-button {
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .gift-card-view {
    padding: 0px 60px;
  }

  .gift-card-content {
    text-align: center !important;
  }

  // .full-width1 {
  //   width: 60% !important;
  // }
  .disclaimer {
    width: 100% !important;
  }

  .dollar {
    float: left;
    margin-top: 17px;
    font-size: 26px;
  }

  .custom-value {
    float: left;
    width: 282px;
    margin-left: 5px;
  }

  .text-hide {
    display: none;
  }
}

@media (min-width: 768px) {
  .desk-dollar {
    display: none;
  }
}

.gift-card-value {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0px 18px 0px;
  }
}

.mat-button-toggle-checked {
  background: #0c83ff;
  color: white !important;
  // border-radius: 22px;
}

/* Loyalty Page CSS */

.loyalty-point-page {
  padding-bottom: 85px;

  .content1 {
    margin-left: 38%;
    margin-right: 38%;
    text-align: center;
    margin-top: 2%;
  }

  .btn-grp {
    width: 70px;
    height: 43px;
    border: none;
    background: white;
  }

  .btn-div {
    border: 1px solid;
    border-radius: 27px;
    width: 60%;
    margin-left: 20%;
  }

  .btn-grpp {
    padding: 3px 22px;
    width: 25%;
  }

  .head-grp {
    border-radius: 32px;
    width: 100%;
    margin-bottom: 14px;
    margin-top: 14px;
  }

  .caim-rwrd {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
  }

  .btns-claim {
    border-radius: 30px !important;
    margin-bottom: 13px;
    padding: 3px 0px;
    border: 1px solid #e5e5e5;
  }

  .btn-first {
    margin-top: 16px;
  }

  .claim-text {
    font-size: 22px;
    font-weight: 500;
    margin-top: 70px;
  }

  .point-have {
    font-size: 31px;
    font-weight: 500;
    margin-top: 13px;
    margin-bottom: 50px;
  }

  .you-have {
    margin-bottom: 0px;
  }

  .redeem {
    justify-content: center;
  }

  .claim-toggle {
    width: 100%;
    height: 51px;
    cursor: pointer;
    background-color: white;
  }

  .mat-button-toggle {
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 45px;
    // color: #979797;
  }

  .mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border-radius: 24px !important;
    border: 1px solid #e5e5e5;
    width: auto;
    display: flex;
    line-height: 45px;
  }

  .mat-button-toggle.mat-button-toggle.bottom-occasion-mat-btn.mat-button-toggle-appearance-standard.ng-star-inserted.mat-button-toggle-checked {
    background-color: #0c83ff;
    color: white;
    border-radius: 24px;
    margin: 0 0px !important;
    line-height: 45px !important;
  }

  .mat-button-toggle.mat-button-toggle.bottom-occasion-mat-btn.mat-button-toggle-appearance-standard.ng-star-inserted {
    // border: 0px;
    width: 100%;
    // color: rgba(0, 0, 0, 0.54);
  }

  .multi-button {
    background-color: #0c83ff;
    color: white;
  }

  .multi-butn-dis {
    background-color: white;
  }

  .h1tag {
    margin-left: 7px;
  }

  .enroll-opt {
    margin-top: 8%;
    margin-left: 12%;
    margin-right: 13%;
  }
}

.loyalty-button {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .loyalty-point-page {
    padding-bottom: 85px;

    .content1 {
      margin-left: 4%;
      margin-right: 4%;
      text-align: center;
      margin-top: 2%;
    }
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .loyalty-point-page {
    padding-bottom: 85px;

    .content1 {
      margin-left: 25%;
      margin-right: 25%;
      text-align: center;
      margin-top: 2%;
    }
  }
}

/* Loyalty Module CSS */
.step-active {
  background: cornflowerblue;
  border: 2px solid cornflowerblue !important;
}

.step-and-bar {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 339px;
  justify-content: center;
  overflow: hidden;
  height: 50px;
}

.step {
  height: 16px;
  width: 16px;
  border: 2px solid #e5e5e5;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.2s;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;

  p {
    margin-top: 25px;
  }
}

.example-margin {
  min-width: 56px !important;
  top: 9px;

  .mat-progress-bar-buffer {
    background-color: #e5e5e5;
  }

  .mat-progress-bar-fill::after {
    background-color: cornflowerblue;
  }
}

.full-screen-modal {
  max-width: unset !important;
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;

    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

.disclaimer_message {
  font-weight: 300;
  text-align: center;
  .custom-font {
    display: flex;
    justify-content: center;
    // height: 68px;
    // padding: 20px 5px;
    // line-height: 25px;
  }

  span {
    width: 100%;
    display: block;
    padding-bottom: 14px;
  }
}


.text-media-web::after {
  content: 'Share';

  @media (max-width: 767px) {
    content: 'Share';
  }
}

.center-text1 {

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.disclaimer_message p, 
.disclaimer_message span {
    line-height: 1.4 !important; /* Override inline line-height */
    margin: 0 !important; /* Remove any inline margins */
    padding: 0 !important; /* Remove any inline paddings */
    text-align: center !important;
}